
  export default {
    scrollToTop: true,
    layout: 'error-page',
     head() {
      return {
        title: this.title + " | Goa App",
        meta: [{
            hid: 'og:title',
            property: 'og:title',
            content: this.title + " | Goa App"
          },
          {
            hid: 'twitter:title',
            property: 'twitter:title',
            content: this.title + " | Goa App"
          },
          {
            hid: 'description',
            name: 'description',
            content: 'goa game login, goa game login app, Goa Games Login, Goa Games, Goa Games Register, goa games online, Goa Games Invitation Code'
          },
          {
            hid: 'keywords',
            name: 'keywords',
            content: 'goa game login, goa game login app, Goa Games Login, Goa Games, Goa Games Register, goa games online, Goa Games Invitation Code'
          },
          {
            hid: 'og:description',
            property: 'og:description',
            content: 'goa game login, goa game login app, Goa Games Login, Goa Games, Goa Games Register, goa games online, Goa Games Invitation Code'
          },
          {
            hid: 'twitter:description',
            property: 'twitter:description',
            content: 'goa game login, goa game login app, Goa Games Login, Goa Games, Goa Games Register, goa games online, Goa Games Invitation Code'
          }
        ],
      }
    },
    data() {
      return {
        title:'Goa Game Login',
        showPass: false,
        form: {
          phoneNumberorEmail: null,
          password: null
        },
        //Form error variables
        errors: {
          formError: null,
          formErrMsg: "Opps! Something went wrong, please try again."
        },
        // Form submit variables
        submit: {
          loading: false,
          success: false,
          errored: false
        }
      }
    },
    // Check login token
    mounted() {
      if (!localStorage.getItem("authToken")) {
        this.$router.push({
          path: '/login/'
        })
      } else {
        this.$router.back()
      }
    },

    methods: {
      // To clear the submit error message
      clearFormErrMsg() {
        this.errors.formError = null;
        this.submit.success = false
        this.submit.errored = false
      },
    }
  }

